import { type FetchResponse } from "ofetch";

export const useLoginError = async (response: FetchResponse<any>) => {
  const localePath = useLocalePath();
  const user = useSanctumUser<User>();
  const { logout } = useSanctumAuth();
  const status = response.status;
  if ([401, 419].includes(status)) {
    // Or
    try {
      await logout();
    } catch (e) {
      user.value = null;
      await navigateTo(localePath("login"));
    }
  }
};
